import {createSlice} from '@reduxjs/toolkit'
import {initialSystem as initialState, IPushMessage} from './types'
import {toast} from 'react-toastify'

const systemSlice = createSlice({
  name: 'systemSlice',
  initialState,
  reducers: {
    setPushMessages(state, action) {
      state.pushMessages = [...action.payload]
    },
    addPushMessage(state, action) {
      const mes: IPushMessage = action.payload
      const messages: IPushMessage[] = state.pushMessages

      // calculate id
      // mes.id =
      //   mes.id == undefined
      //     ? state.pushMessages.length
      //       ? messages.reduce(
      //           (acc: number, m: IPushMessage) => (m.id ? (acc < m.id ? m.id : acc) : acc),
      //           0
      //         ) + 1
      //       : 0
      //     : mes.id
      // mes.showSeconds = mes.showSeconds ? mes.showSeconds : 5
      // state.pushMessages = [...state.pushMessages, mes]

      let detailsMessages = mes.details

      if (mes.details && Array.isArray(mes.details)) {
        detailsMessages = mes.details.join(', ')
      }

      toast(detailsMessages.length > 0 ? detailsMessages : mes.message, {
        type: mes.status,
      })
    },
    addPushMessageGroup(state, action) {
      console.log('reducers addPushMessageGroup begin', action.payload)
      const mess: IPushMessage[] = action.payload
      const messages: IPushMessage[] = state.pushMessages

      // calculate ids
      let mess_upd = mess.map((mes: IPushMessage) => {
        mes.id =
          mes.id == undefined
            ? state.pushMessages.length
              ? messages.reduce(
                  (acc: number, m: IPushMessage) => (m.id ? (acc < m.id ? m.id : acc) : acc),
                  0
                ) + 1
              : 0
            : mes.id
        mes.showSeconds = mes.showSeconds ? mes.showSeconds : 5
        return mes
      })
      console.log('reducers addPushMessageGroup', mess_upd)
      state.pushMessages = [...state.pushMessages, ...mess_upd]
    },
    delPushMessageById(state, action) {
      console.log('delPushMessageById', action.payload)

      state.pushMessages = [...state.pushMessages.filter((x) => x.id != action.payload)]
    },
    setPushMessage(state, action) {
      state.pushMessages = [
        ...state.pushMessages.map((x) => {
          if (x.id == action.payload.id) return action.payload
          else return x
        }),
      ]
    },
  },
})

export const systemReducer = systemSlice.reducer
export const {
  setPushMessages,
  addPushMessage,
  delPushMessageById,
  addPushMessageGroup,
  setPushMessage,
} = systemSlice.actions
