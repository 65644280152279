import {ToastContainer as ToastifyContainer, Bounce} from 'react-toastify'

const ToastContainer = () => {
  return (
    <ToastifyContainer
      position='bottom-left'
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme='colored'
      transition={Bounce}
    />
  )
}

export default ToastContainer
