import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {createReduxEnhancer} from '@sentry/react'
import {reduxBatch} from '@manaflair/redux-batch'
import {apiSlice} from '../api/apiSlice'
import {toastMiddleware} from './middleware/toastMiddleware'
import {ozonCookieApiSlice} from '../../app/store/mpWizard/api'
import {persistStore} from 'redux-persist'
import {rootReducer, rootSaga} from './RootReducer'

const sentryReduxEnhancer = createReduxEnhancer({})

const sagaMiddleware = createSagaMiddleware({
  onError: (er: Error, saga: any) => console.log('saga error', er, saga),
})
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
  apiSlice.middleware,
  ozonCookieApiSlice.middleware,
  toastMiddleware.middleware,
]

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch, sentryReduxEnhancer],
})

export type AppDispatch = typeof store.dispatch

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
